.btn,
.disabledBtn {
  padding: .4% 2% !important;
flex: 0 0 10%;
width: 20%;
  margin-right: 5%;
  /* color: white !important; */
  border: none !important;
  outline: 0 !important;
  cursor: pointer;
  border-radius: 6px;
  font-size: 1.2em !important;
  font-weight: 600 !important;
}
.btn-buy {
  border:2px solid  #009999!important;
  color:  #009999!important;
}

.btn-sell {
  border:2px solid  #ff6666 !important;
  color:  #ff6666 !important;
  
}
.btn-sell:hover{
  background-color:  #ff6666 !important;
  color:white !important;
}
.btn-buy:hover{
  background-color:  #009999!important;
  color:white !important;
}
.btn-reset,
.resetDisabledBtn{
  display:flex;
  align-items: center;
  justify-content: space-around;  
  border:2px solid #F4A460!important;
  color: #F4A460!important;
 width: 80%;
 flex:0 0 80%;
 
  
}
.disabledBtn,
.resetDisabledBtn {
  background-color: rgb(197, 197, 197) !important;
  pointer-events: none;
  border: none !important;
  outline: 0 !important;
  color: black !important;
}
.btn-reset:hover{
  background-color:  #F4A460!important;
  color:white !important;
}


.btn-container {
  margin-top: 1.1%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* padding: 1%; */
  /* height:10vh; */
  /* width:30%; */
  margin-left: 35%;
  margin-right: 4%;
  margin-top: 2%;
}
.reset-container{
  /* margin-bottom: 2%; */
  display: flex;
  align-items: center;
  flex-direction: column;
}


.pnl_box
 {
  
  display: flex;
  align-items: center;
  width:30%;
}
.pnl_title {
  color: rgb(146, 146, 146);
  font-size: .9em;
  width:45%;
}

.pnl_val{
  color:rgb(28, 27, 33);
  width:45%;
}

#chart,
.status {
  /* width: 100%; */
  /* margin-left: 10vw; */
  /* height:6vh; */
}
.pnl-container{
  width: 100%;
  height: 65vh
}
#chart {
  height: 65vh;
  width: 75%;
  margin-right: 2%;
  /* margin-left: 10%;
  margin-right: 10%; */
  /* position:absolute;  */
}
.status{
  text-align: center;
  font-size:1.2em;font-weight:bold;  color: rgba(146, 146, 146, 0.809);
}

/* .options {
  padding: 20px;
  margin-top: 20px;
  background-color: rgba(191, 191, 191, 0.15);
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  margin-top: 10px;
}

.option > span {
  width: 50px;
  display: inline-block;
  margin-right: 10px;
}

.option > .dx-selectbox {
  display: inline-block;
  vertical-align: middle;
} */