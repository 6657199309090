@import url(//hello.myfonts.net/count/3bc434);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn,
.disabledBtn {
  padding: .4% 2% !important;
flex: 0 0 10%;
width: 20%;
  margin-right: 5%;
  /* color: white !important; */
  border: none !important;
  outline: 0 !important;
  cursor: pointer;
  border-radius: 6px;
  font-size: 1.2em !important;
  font-weight: 600 !important;
}
.btn-buy {
  border:2px solid  #009999!important;
  color:  #009999!important;
}

.btn-sell {
  border:2px solid  #ff6666 !important;
  color:  #ff6666 !important;
  
}
.btn-sell:hover{
  background-color:  #ff6666 !important;
  color:white !important;
}
.btn-buy:hover{
  background-color:  #009999!important;
  color:white !important;
}
.btn-reset,
.resetDisabledBtn{
  display:flex;
  align-items: center;
  justify-content: space-around;  
  border:2px solid #F4A460!important;
  color: #F4A460!important;
 width: 80%;
 flex:0 0 80%;
 
  
}
.disabledBtn,
.resetDisabledBtn {
  background-color: rgb(197, 197, 197) !important;
  pointer-events: none;
  border: none !important;
  outline: 0 !important;
  color: black !important;
}
.btn-reset:hover{
  background-color:  #F4A460!important;
  color:white !important;
}


.btn-container {
  margin-top: 1.1%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* padding: 1%; */
  /* height:10vh; */
  /* width:30%; */
  margin-left: 35%;
  margin-right: 4%;
  margin-top: 2%;
}
.reset-container{
  /* margin-bottom: 2%; */
  display: flex;
  align-items: center;
  flex-direction: column;
}


.pnl_box
 {
  
  display: flex;
  align-items: center;
  width:30%;
}
.pnl_title {
  color: rgb(146, 146, 146);
  font-size: .9em;
  width:45%;
}

.pnl_val{
  color:rgb(28, 27, 33);
  width:45%;
}

#chart,
.status {
  /* width: 100%; */
  /* margin-left: 10vw; */
  /* height:6vh; */
}
.pnl-container{
  width: 100%;
  height: 65vh
}
#chart {
  height: 65vh;
  width: 75%;
  margin-right: 2%;
  /* margin-left: 10%;
  margin-right: 10%; */
  /* position:absolute;  */
}
.status{
  text-align: center;
  font-size:1.2em;font-weight:bold;  color: rgba(146, 146, 146, 0.809);
}

/* .options {
  padding: 20px;
  margin-top: 20px;
  background-color: rgba(191, 191, 191, 0.15);
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  margin-top: 10px;
}

.option > span {
  width: 50px;
  display: inline-block;
  margin-right: 10px;
}

.option > .dx-selectbox {
  display: inline-block;
  vertical-align: middle;
} */
/**
 * @license
 * MyFonts Webfont Build ID 3916852, 2020-06-22T02:11:34-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: FreightSansProSemibold-Regular by GarageFonts
 * URL: https://www.myfonts.com/fonts/garagefonts/freight-sans-pro/semibold/
 * Copyright: Copyright &amp;#x00A9; 2004-2009, Joshua Darden &amp;amp; Phil&#x27;s Fonts, Inc. All rights reserved. Published by Garagefonts USA 301-879-9222 www.garagefonts.com
 * 
 * Webfont: FreightSansProBold-Regular by GarageFonts
 * URL: https://www.myfonts.com/fonts/garagefonts/freight-sans-pro/bold/
 * Copyright: Copyright &amp;#x00A9; 2004-2009, Joshua Darden &amp;amp; Phil&#x27;s Fonts, Inc. All rights reserved. Published by Garagefonts USA 301-879-9222 www.garagefonts.com
 * 
 * Webfont: FreightSansProBook-Regular by GarageFonts
 * URL: https://www.myfonts.com/fonts/garagefonts/freight-sans-pro/book/
 * Copyright: Copyright &amp;#x00A9; 2004-2010, Joshua Darden &amp;amp; Phil&#x27;s Fonts, Inc. All rights reserved. Published by Garagefonts USA 301-879-9222 www.garagefonts.com
 * 
 * 
 * 
 * © 2020 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
  
@font-face {
  font-family: 'FreightSansProBook-Regular';
  src: url(/static/media/font.19d0f666.woff2) format('woff2'), url(/static/media/font.74aeb674.woff) format('woff');
}
@font-face {
  font-family: 'FreightSansProBold-Regular';
  src: url(/static/media/font.eb57b393.woff2) format('woff2'), url(/static/media/font.d24196a9.woff) format('woff');
}
@font-face {
  font-family: 'FreightSansProSemibold-Regular';
  src: url(/static/media/font.ecdbfcb4.woff2) format('woff2'), url(/static/media/font.ed9222a9.woff) format('woff');
}
@font-face {
  font-family: 'Roboto';
  src: url(/static/media/Roboto-Regular.6bede58e.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url(/static/media/Roboto-Bold.17451a4c.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}

.appLoader_loader__wyrcn{border:5px solid #fff;border-top:5px solid #32b265;border-radius:50%;width:40px;height:40px;animation:appLoader_spin__PgO2p 2s linear infinite;position:fixed;top:45%;left:48%;z-index:1302}@keyframes appLoader_spin__PgO2p{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}.appLoader_appOverlay__2C2tK{position:fixed;width:100%;height:100%;background-color:#fff;padding:10px;opacity:.5;z-index:1301}
